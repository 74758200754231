// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/main-background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#intro{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;background-size:cover;color:#fff;text-align:center}#intro .flex-column{height:100vh;justify-content:space-around;padding:30px 0 100px}@media screen and (max-width: 768px){#intro .flex-column{height:auto}}#intro h1{font-size:48px;margin:0}@media screen and (max-width: 768px){#intro h1{font-size:36px;margin-top:15px}}#intro h2{font-size:26px;margin:0}@media screen and (max-width: 768px){#intro h2{font-size:22px}}#intro .subtitle-line span{display:block;line-height:0;position:relative}#intro .subtitle-line span:before,#intro .subtitle-line span:after{content:'';width:100px;height:3px;background:#fff;position:absolute;top:50%}#intro .subtitle-line span:before{right:100%;margin-right:20px}#intro .subtitle-line span:after{left:100%;margin-left:20px}#intro .subtitle-line span svg{height:40px;width:40px}\n", ""]);
// Exports
module.exports = exports;
